import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { LanguageLocale, NodeLocale } from "@components/Shared/model/LanguageContent";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Badge } from "@ryerson/frontend.notification";
import { Icon } from "@ryerson/frontend.assets";
import { Breadcrumb, Link } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import SocialMedia from "@components/NewsEvents/SocialMedia/SocialMedia";
import styled from "@emotion/styled";
import { renderBlogRichText } from "@components/Shared/model/Contentful";
import { PopularContent } from "./PopularContent";
import { css } from "@emotion/react";
import StayTunedComponent from "@components/Shared/StayTuned/StayTuned";
import { SpecialForm } from "./SpecialForm";
import { BlogCategory } from "./Category";
import MetalInsightsAndResourcesRefactor from "@components/Shared/MetalInsights/MetalInsightsAndResourcesRefactor";
import { defaultStaticContent } from "@components/Shared/MetalInsights/MetalInsightsAndResourcesRefactor";

export type ContenfulBlogNode = {
	body: any;
	publishDate: string;
	seoDescription: any;
	seoImage?: any;
	seoKeywords?: string;
	heroImage: any;
	seoTitle?: string;
	slug: string;
	subTitle: any;
	title: string;
	node_locale: NodeLocale;
	categories: BlogCategory[];
	showContactForm?: boolean;
};

export type StaticBlogContent = {
	popularHeading: string;
	ryersonGuideTitle: string;
	ryersonGuideSubTitle: string;
	ryersonGuideUrl: string;
};

export type ContentfulNode = {
	node: ContenfulBlogNode;
};

export type BlogProps = {
	contentfulContent: ContentfulNode[];
	content: StaticBlogContent;
};

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 0px;
			}
		`;
	}}
`;

const Divider = styled.hr`
	margin-top: 0px;
	margin-bottom: 0px;
	opacity: 0.1;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 10px;
`;

const GiantSpacer = styled.div`
	display: block;
	width: 100%;
	height: 270px;
`;

const Left = styled.div`
	display: inline-block;
	width: calc(100% - 300px);
	vertical-align: top;
	padding: 0;
	box-sizing: border-box;
	height: auto;
	border-radius: 2px;
	margin-top: -270px;
	position: relative;
	margin-bottom: 60px;
`;

const Right = styled.div`
	display: inline-block;
	width: 290px;
	margin-top: -270px;
	vertical-align: top;
	margin-left: 10px;
`;

const Social = styled.div`
	width: 20px;
	height: auto;
	left: -36px;
	position: absolute;
`;

const Hero = styled.div`
	width: 100%;
	height: auto;
	position: relative;
	display: block;
	img {
		width: 100%;
		height: auto;
		display: block;
	}
`;

const FlexContainer = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	padding: 40px 44px;
	z-index: 1;
	box-sizing: border-box;
	left: 0;
`;

const RichContainer = styled.div`
	display: block;
	width: 100%;
	height: auto;
	box-sizing: border-box;
	padding: 50px 0px;
`;

const DownloadSheet = styled.div`
	display: block;
	width: 100%;
	height: auto;
	margin-top: 10px;
	border-radius: 2px;
	overflow: hidden;
`;

const DownloadSheetImage = styled.div`
	display: block;
	height: auto;
	width: 100%;
	position: relative;
	img {
		width: 100%;
		height: auto;
	}
`;

const DownloadLink = styled.div`
	display: block;
	width: 100%;
	height: auto;
	margin-top: 15px;
	padding: 0 3px;
	box-sizing: border-box;
`;

const stayTunedParent = "Blog-Article";
const Blog: React.FC<BlogProps> = ({ contentfulContent, content }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	const [socialReference, setSocialReference] = React.useState<any>(React.createRef());
	const [socialTop, setSocialTop] = React.useState<number>(0);
	React.useEffect(() => {
		if (window !== undefined && document !== undefined) {
			//window.addEventListener("scroll", function () {
			if (socialReference.current) {
				let offset = socialReference.current.getBoundingClientRect();
				let startingTop = offset.top - document.body.getBoundingClientRect().top;
				if (
					window.scrollY + 120 >= startingTop &&
					window.scrollY + 265 < socialReference.current.offsetHeight + startingTop
				) {
					setSocialTop(window.scrollY - startingTop + 120);
				} else if (
					socialReference.current.offsetHeight + startingTop <=
					window.scrollY + 265
				) {
					setSocialTop(socialReference.current.offsetHeight - 145);
				} else {
					setSocialTop(0);
				}
			}
			// });
		}
		if (!socialReference) setSocialReference(React.createRef());
	}, []);

	const [languageContent, setLanguageContent] = React.useState<ContenfulBlogNode>(
		contentfulContent[0].node
	);
	React.useEffect(() => {
		contentfulContent?.forEach((data: ContentfulNode) => {
			if (LanguageLocale[data.node?.node_locale] === language) setLanguageContent(data.node);
		});
	}, [language]);

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="20px">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>

					<Media lessThan="xl">
						<Spacer />
						<Spacer />
						<SocialMedia orientation="horizontal" type="tertiary" />
					</Media>

					<GiantSpacer />
				</ContentSection>
				<ContentSection type="secondary">
					<Left
						ref={socialReference}
						css={css`
							background-color: ${theme.colors.primary.background};
						`}
					>
						<Media greaterThan="lg">
							<Social
								css={css`
									top: ${socialTop + "px"};
								`}
							>
								<SocialMedia orientation="vertical" type="tertiary" />
							</Social>
						</Media>
						<Hero>
							<img src={languageContent?.heroImage?.file?.url} />
							<FlexContainer>
								<Flex
									css={css`
										width: 100%;
										height: 100%;
									`}
									justifyContent="space-between"
									direction="column"
								>
									<FlexItem>
										{languageContent.categories &&
											languageContent?.categories.length > 0 &&
											languageContent?.categories.map(
												(category: any, index: number) => {
													return (
														<div
															key={index}
															css={css`
																display: inline-block;
																vertical-align: top;
																margin-bottom: 5px;
																margin-right: 5px;
															`}
														>
															<Badge
																color={category.categoryColor}
																type="primary"
																key={index}
															>
																{category.title}
															</Badge>
														</div>
													);
												}
											)}
									</FlexItem>
									<FlexItem>
										<Typography
											variant="h1"
											css={css`
												max-width: 700px;
												margin-top: 0px;
												letter-spacing: -2px;
												margin-bottom: 0px;
												text-shadow: 0px 0px 4px
													${theme.colors.primary.darkerGray};
											`}
											type="tertiary"
										>
											{languageContent?.title}
										</Typography>
									</FlexItem>
								</Flex>
							</FlexContainer>
						</Hero>
						<RichContainer>
							{languageContent?.subTitle &&
							languageContent.subTitle.subTitle.length > 0 ? (
								<Typography
									css={css`
										margin-bottom: 56px;
										max-width: 560px;
										letter-spacing: -1px;
										margin-top: 0px;
										margin-left: 40px;
										width: calc(100% - 40px);
										font-size: 28px;
										line-height: 30px;
									`}
									type="primary"
									variant="h2"
								>
									{languageContent.subTitle.subTitle}
								</Typography>
							) : (
								<></>
							)}
							<Typography
								variant="div"
								type="primary"
								size="md"
								css={css`
									> p {
										margin-bottom: 18px;
										margin-top: 0px;
										max-width: 560px;
										margin-left: 40px;
										width: calc(100% - 40px);
									}
									> ul {
										margin-bottom: 18px;
										margin-top: 0px;
										max-width: 560px;
										margin-left: 40px;
										box-sizing: border-box;
										width: calc(100% - 40px);
									}
									> h2 {
										margin-top: 36px;
										margin-bottom: 42px;
										max-width: 560px;
										margin-left: 40px;
										width: calc(100% - 40px);
									}
									> h3 {
										margin-top: 0px;
										margin-bottom: 4px;
										max-width: 560px;
										margin-left: 40px;
										width: calc(100% - 40px);
									}
									> h4 {
										margin-top: 0px;
										margin-bottom: 4px;
										max-width: 560px;
										margin-left: 40px;
										width: calc(100% - 40px);
									}
									> img {
										max-width: 100%;
										display: block;
										max-width: calc(100% - 40px);
										margin-left: 40px;
									}
								`}
							>
								{renderBlogRichText(
									languageContent?.body?.raw,
									"primary",
									languageContent?.body?.references
								)}
							</Typography>
						</RichContainer>
					</Left>
					<Right>
						<PopularContent
							title={content.popularHeading}
							excludeSlug={languageContent?.slug}
						/>
						<Spacer
							css={css`
								height: 10px;
							`}
						/>
						<StayTunedComponent
							parent={stayTunedParent}
							parentCategory={JSON.stringify(
								languageContent?.categories.map((category: BlogCategory) => {
									return category.title;
								})
							)}
							slim={true}
						/>
						<DownloadSheet>
							<DownloadSheetImage>
								<img src="/images/metal-resources/metal-knowledge/NotSure.jpg" />
								<Typography
									variant="div"
									size="sm"
									color={theme.colors.primary.gray}
									css={css`
										position: absolute;
										left: 24px;
										bottom: 24px;
										width: calc(100% - 48px);
										height: auto;
										z-index: 1;
									`}
								>
									{content.ryersonGuideSubTitle}
								</Typography>
							</DownloadSheetImage>
							<DownloadLink>
								<Link to={content.ryersonGuideUrl}>
									<Icon
										icon="file-pdf"
										size="sm"
										color={theme.colors.primary.link}
										css={css`
											display: inline-block;
											vertical-align: top;
										`}
									/>
									<Typography
										variant="h3"
										color={theme.colors.primary.link}
										css={css`
											font-size: 20px;
											line-height: 25px;
											margin-top: 0px;
											margin-bottom: 0px;
											display: inline-block;
											margin-left: 6px;
											letter-spacing: -1px;
											width: calc(100% - 24px);
											vertical-align: top;
										`}
									>
										{content.ryersonGuideTitle}
									</Typography>
								</Link>
							</DownloadLink>
						</DownloadSheet>
					</Right>
					<Divider
						css={css`
							color: ${theme.colors.primary.darkerGray};
						`}
					/>
				</ContentSection>
				<SpecialForm />
				<MetalInsightsAndResourcesRefactor />
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary" vPadding="20px">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Spacer />
					<Spacer />
					<Hero>
						<img src={languageContent?.heroImage?.file?.url} />
						<FlexContainer
							css={css`
								padding: 24px 20px;
							`}
						>
							{languageContent?.categories &&
								languageContent?.categories.length > 0 &&
								languageContent?.categories.map((category: any, index: number) => {
									return (
										<div
											key={index}
											css={css`
												display: inline-block;
												vertical-align: top;
												margin-bottom: 5px;
												margin-right: 5px;
											`}
										>
											<Badge
												color={category.categoryColor}
												type="primary"
												key={index}
											>
												{category.title}
											</Badge>
										</div>
									);
								})}
						</FlexContainer>
					</Hero>
					<Typography
						variant="h3"
						css={css`
							margin-top: 24px;
							letter-spacing: -1px;
							margin-bottom: 16px;
						`}
						type="tertiary"
					>
						{languageContent?.title}
					</Typography>
					<Spacer />
					<SocialMedia orientation="horizontal" type="tertiary" />
					<Spacer />
					<Spacer />
				</ContentSection>
				<ContentSection type="primary" vPadding="32px" hPadding="0px">
					{languageContent?.subTitle && languageContent.subTitle.subTitle.length > 0 ? (
						<Typography
							css={css`
								margin-bottom: 35px;
								letter-spacing: -1px;
								margin-top: 0px;
								font-size: 20px;
								line-height: 24px;
								margin-left: 20px;
								max-width: calc(100% - 40px);
								font-size: 24px !important;
								line-height: 30px !important;
							`}
							type="primary"
							variant="h2"
						>
							{languageContent.subTitle.subTitle}
						</Typography>
					) : (
						<></>
					)}
					<Typography
						variant="div"
						type="primary"
						size="md"
						css={css`
							> ul {
								margin-bottom: 16px;
								margin-top: 0px;
								margin-left: 20px;
								max-width: calc(100% - 40px);
							}
							> p {
								margin-bottom: 16px;
								margin-top: 0px;
								margin-left: 20px;
								max-width: calc(100% - 40px);
							}
							> h2 {
								margin-top: 32px;
								margin-bottom: 36px;
								margin-left: 20px;
								max-width: calc(100% - 40px);
							}
							> h3 {
								margin-top: 0px;
								margin-bottom: 2px;
								margin-left: 20px;
								max-width: calc(100% - 40px);
							}
							> h4 {
								margin-top: 0px;
								margin-bottom: 2px;
								margin-left: 20px;
								max-width: calc(100% - 40px);
							}
							> img {
								max-width: 100%;
								display: block;
								margin-left: 20px;
								max-width: calc(100% - 40px);
							}
						`}
					>
						{renderBlogRichText(
							languageContent?.body?.raw,
							"primary",
							languageContent?.body?.references
						)}
					</Typography>

					<ContentSection vPadding="20px" type="primary">
						<SocialMedia
							color={theme.colors.primary.primaryBrand}
							type="primary"
							orientation="horizontal"
						/>
						<Divider
							css={css`
								margin-top: 8px;
								margin-bottom: 56px;
								color: ${theme.colors.primary.darkerGray};
							`}
						/>
						<PopularContent
							title={content.popularHeading}
							excludeSlug={languageContent?.slug}
						/>
						<Spacer
							css={css`
								height: 10px;
							`}
						/>
					</ContentSection>
					<StayTunedComponent
						parent={stayTunedParent}
						parentCategory={JSON.stringify(
							languageContent?.categories.map((category: BlogCategory) => {
								return category.title;
							})
						)}
					/>
					<ContentSection vPadding="20px" type="primary">
						<DownloadSheet>
							<DownloadSheetImage>
								<img src="/images/metal-resources/metal-knowledge/NotSure.jpg" />
								<Typography
									variant="div"
									size="sm"
									color={theme.colors.primary.gray}
									css={css`
										position: absolute;
										left: 24px;
										bottom: 24px;
										width: calc(100% - 48px);
										height: auto;
										z-index: 1;
									`}
								>
									{content.ryersonGuideSubTitle}
								</Typography>
							</DownloadSheetImage>
							<DownloadLink>
								<Link to={content.ryersonGuideUrl}>
									<Icon
										icon="file-pdf"
										size="sm"
										color={theme.colors.primary.link}
										css={css`
											display: inline-block;
											vertical-align: top;
										`}
									/>
									<Typography
										variant="div"
										size="lg"
										weight="bold"
										color={theme.colors.primary.link}
										css={css`
											margin-top: 0px;
											margin-bottom: 0px;
											display: inline-block;
											margin-left: 6px;
											width: calc(100% - 24px);
											vertical-align: top;
										`}
									>
										{content.ryersonGuideTitle}
									</Typography>
								</Link>
							</DownloadLink>
						</DownloadSheet>
					</ContentSection>
				</ContentSection>
				<SpecialForm />
				<ContentSection>
					<Typography
						variant="h3"
						weight="bold"
						css={css`
							font-size: 20px;
							line-height: 25px;
							margin-top: 0px;
						`}
					>
						{defaultStaticContent[language].title1 +
							" " +
							defaultStaticContent[language].titleSeparator +
							" " +
							defaultStaticContent[language].title2}
					</Typography>
					<MetalInsightsAndResourcesRefactor />
				</ContentSection>
			</Media>
		</>
	);
};

export default Blog;
